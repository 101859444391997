/*@font-face {*/
/*    font-family: 'Vazir FD';*/
/*    src: url('../assets/fonts/Vazir-Regular-FD.woff') format('woff'),*/
/*    url('../assets/fonts/Vazir-Thin-FD.woff') format('woff'),*/
/*    url('../assets/fonts/Vazir-Light-FD.woff') format('woff'),*/
/*    url('../assets/fonts/Vazir-Medium-FD.woff') format('woff'),*/
/*    url('../assets/fonts/Vazir-Bold-FD.woff') format('woff'),*/
/*    url('../assets/fonts/Vazir-Black-FD.woff') format('woff');*/
/*}*/

body {
    margin: 0;
    font-family: 'Vazir FD', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    background: #eee;
    border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 16px;
    border: 3px solid #aaa;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
