@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-one: #314d85;
    --color-two: #8d7f65;
    --color-three: #d8d0c5;
    --color-four: #f2eadf;
    --color-five: #f9f9f9;
    --color-six: #0d0d0d;
    --color-seven: #efefef;
    --color-test-1: #14163f;
    --color-test-2: #fff;
    --color-test-3: #F0EFED;

    --color-eight: #3f3d56; /* wireframe */
    --color-nine: #0d0d0d; /* wireframe */
    --color-ten: #e6e6e6; /* wireframe */
  }
}
